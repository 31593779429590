import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import Backend, { BackendOptions } from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import { languagesList, DEFAULT_LOCALE } from "./data";

// front locales
import en from "./en/translation.json";
import uk from "./uk/translation.json";
import ru from "./ru/translation.json";

import { convertLanguageJsonToObject } from "./translations";

export const translationsJson = {
  en: {
    translation: en,
  },
  uk: {
    translation: uk,
  },
  ru: {
    translation: ru,
  },
};

// const getLocaleKey = () => {
//   let storageKey = localStorage.getItem('i18nextLng');
//   return storageKey ? storageKey : DEFAULT_LOCALE;
// };

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en);

export const i18n = i18next
  // .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: translationsJson,
    // backend: {
    //   loadPath: `${process.env.REACT_APP_API_URL}/api/v1/translations`,
    //   customHeaders: {
    //     Accept: 'application/json',
    //     'X-localization': getLocaleKey(),
    //   },
    //   // @ts-ignore
    //   parse: function (data: string, languages: string) {
    //     console.log('parse', { data });
    //     // return data;
    //     let localTranslation = translationsJson[languages].translation;
    //     return data.length
    //       ? Object.assign({}, JSON.parse(data), localTranslation)
    //       : localTranslation;
    //   },
    // },
    react: {
      useSuspense: true,
    },
    supportedLngs: languagesList.map((lang) => lang.id),
    load: "languageOnly",
    // lng: getLocaleKey(),
    fallbackLng: "en",
    debug: process.env.NODE_ENV !== "production",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
