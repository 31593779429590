const userCreate = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
       aria-hidden="true" width="24" height="24" className="text-inherit stroke-[1.2px]">
    <path strokeLinecap="round" strokeLinejoin="round"
  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"/>
  </svg>

);

export default userCreate;


