// core
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// components
import Logo from "../../../icons/logo";
import Close from "../../../icons/close";
import LogoAvatar from "../../../icons/logoAvatar";
import UserCreate from "../../../icons/userCreate";

// styles
import "./index.scss";

// data
import { SIDEBAR_SOCIAL } from "./data";

const Sidebar = ({ isActive, onClose }: any) => {
  // translation hook
  const { t, i18n } = useTranslation();

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <div className={"sidebar" + (isActive ? " sidebar--active" : "")}>
      <div className="sidebar__overlay" onClick={onCloseHandler} />
      <aside className="sidebar__aside">
        <div className="sidebar__head">
          <div className="sidebar__logo">
            <RouterLink to={"/lp/"}>
              <Logo />
            </RouterLink>
          </div>
          <div className="sidebar__close" onClick={onCloseHandler}>
            <Close />
          </div>
        </div>
        <div className="sidebar__body">
          <div className="sidebar__content">
            <div className="sidebar__actions">
              <a
                href={`https://${window.location.hostname}/auth/log-in`}
                className="app__btn--outline sidebar__btn sidebar__btn--sign"
              >
                <LogoAvatar />
                {t("actions.login")}
              </a>
              <a
                href={`https://${window.location.hostname}/auth/sign-up?lng=${i18n.language}`}
                className="app__btn sidebar__btn sidebar__btn--create"
              >
                <UserCreate />
                {t("actions.signin")}
              </a>
            </div>
          </div>
          <div className="sidebar__footer">
            <div className="sidebar__footer-title">
              {t("sidebar.social.title")}
            </div>
            <ul className="sidebar__footer-links">
              {SIDEBAR_SOCIAL.map((item: any, index: number) => (
                <li key={index}>
                  <a href={item.url} title={item.name} className="app__link">
                    {<item.icon />}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
