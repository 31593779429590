const instagram = (props: any) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1904 0H5.80969C2.60621 0 0 2.60621 0 5.80969V14.1903C0 17.3938 2.60621 20 5.80969 20H14.1903C17.3938 20 20 17.3938 20 14.1904V5.80969C20 2.60621 17.3938 0 14.1904 0ZM18.4375 14.1903C18.4375 16.5322 16.5322 18.4375 14.1904 18.4375H5.80969C3.46777 18.4375 1.5625 16.5322 1.5625 14.1904V5.80969C1.5625 3.46777 3.46777 1.5625 5.80969 1.5625H14.1903C16.5322 1.5625 18.4375 3.46777 18.4375 5.80969V14.1903Z"
        fill="currentColor"/>
      <path
        d="M10 4.60938C7.02758 4.60938 4.60938 7.02758 4.60938 10C4.60938 12.9724 7.02758 15.3906 10 15.3906C12.9724 15.3906 15.3906 12.9724 15.3906 10C15.3906 7.02758 12.9724 4.60938 10 4.60938ZM10 13.8281C7.88918 13.8281 6.17188 12.1108 6.17188 10C6.17188 7.88918 7.88918 6.17188 10 6.17188C12.1108 6.17188 13.8281 7.88918 13.8281 10C13.8281 12.1108 12.1108 13.8281 10 13.8281Z"
        fill="currentColor"/>
      <path
        d="M15.4688 5.3125C15.9002 5.3125 16.25 4.96272 16.25 4.53125C16.25 4.09978 15.9002 3.75 15.4688 3.75C15.0373 3.75 14.6875 4.09978 14.6875 4.53125C14.6875 4.96272 15.0373 5.3125 15.4688 5.3125Z"
        fill="currentColor"/>
    </svg>

  )
;

export default instagram;


