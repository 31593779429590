// core
import React, {useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
// utils

// locales

// store

// types

// components
import Logo from "../../icons/logo";
import ThemeToggler from '../ThemeToggler'
import LangToggler from "../LangToggler";
import HeaderNavMobile from "./HeaderNavMobile";
import Menu from "../../icons/menu";
import Sidebar from "./Sidebar";

// styles
import './index.scss';


// data

// func
const HeaderMobile = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  const onToggleSidebar = (flag: boolean) => {
    if (flag) {
      // @ts-ignore
      document.querySelector('body').classList.add('body--fixed');
    } else {
      // @ts-ignore
      document.querySelector('body').classList.remove('body--fixed');
    }
    setIsSidebarActive(flag)
  }

  return (
    <>
      <header className='header-mobile'>
        <div className='app__wrapper'>
          <div className='header-mobile__content'>
            <div className='header-mobile__logo'>
              <RouterLink to={'/lp/'}>
                <Logo />
              </RouterLink>
            </div>

            <div className='header-mobile__actions'>
              <div className='header-mobile__nav'>
                <HeaderNavMobile/>
              </div>
              <div className='header-mobile__theme-toggler'>
                <LangToggler/>
              </div>
              <div className='header-mobile__lang-toggler'>
                <ThemeToggler/>
              </div>

              <div className='header-mobile__btns'>
                <button className='header-mobile__btn' onClick={()=>onToggleSidebar(true)}>
                  <Menu/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Sidebar isActive={isSidebarActive} onClose={() => onToggleSidebar(false)}/>
    </>
  );
};

export default HeaderMobile;
