const Moon = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.3542 15.3539C19.3176 15.7705 18.1856 15.9997 17 15.9997C12.0294 15.9997 8 11.9703 8 6.9997C8 5.81412 8.22924 4.68209 8.64581 3.64551C5.33648 4.97543 3 8.21471 3 11.9997C3 16.9703 7.02944 20.9997 12 20.9997C15.785 20.9997 19.0243 18.6632 20.3542 15.3539Z"
      stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

);

export default Moon;


