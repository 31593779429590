// core
import React from 'react';

// components
import Logo from "../../icons/logo";

// styles
import './index.scss'


const Loader = ({active}: any) => {
  return (
    <div className={'loader' + (active ? ' loader--active': '')}>
      <div className="loader__content">
        <Logo />
      </div>
    </div>
  );
};

export default Loader;
