import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import camelCaseKeys from "camelcase-keys";

/* ========================================================================== */
/*                                    Types                                   */
/* ========================================================================== */

type BaseRequestParams = {
  signal?: AbortSignal;
};
interface GeolocationResponseData {
  countryCode: string;
  countryName: string;
}

type GeolocationResponse = {
  data: GeolocationResponseData;
};

/* ========================================================================== */
/*                                 Axios init                                 */
/* ========================================================================== */

const api = axios.create({
  baseURL: "https://geo-pay.net/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

/* ========================================================================== */
/*                             getCountry endpoint                            */
/* ========================================================================== */

const getGeolocation = async ({ signal }: BaseRequestParams) => {
  const response: AxiosResponse<GeolocationResponse> = await api.get(
    "/v3/ip/country/",
    { signal },
  );
  const data = camelCaseKeys<GeolocationResponseData>(response.data.data, {
    deep: true,
  });

  return data;
};

/* ========================================================================== */
/*                             useGeolocation hook                            */
/* ========================================================================== */

const useGeolocation = () => {
  return useQuery<GeolocationResponseData, AxiosError>(
    ["countries"],
    ({ signal }) =>
      getGeolocation({
        signal,
      }),
    {
      initialData: undefined,
      refetchOnWindowFocus: false,
    },
  );
};

export { useGeolocation };
export default useGeolocation;
