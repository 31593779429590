import Facebook from '../../../icons/facebook'
import Instagram from '../../../icons/instagram'
import Twitter from '../../../icons/twitter'
import YouTube from '../../../icons/youtube'

const SIDEBAR_SOCIAL = [
  {
    name: 'Facebook',
    icon: Facebook,
    url: 'https://www.facebook.com/geo.pay.u/'
  },
  {
    name: 'Instagram',
    icon: Instagram,
    url: 'https://www.instagram.com/_geopay_/'
  },
  {
    name: 'Twitter',
    icon: Twitter,
    url: 'https://twitter.com/geo_pay'
  },
  {
    name: 'YouTube',
    icon: YouTube,
    url: 'https://www.youtube.com/channel/UCxDeUTbxF6eo5YXnXi-MSKg '
  }
]

export {SIDEBAR_SOCIAL};
