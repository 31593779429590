import Facebook from "../../icons/facebook";
import Instagram from "../../icons/instagram";
import Twitter from "../../icons/twitter";
import YouTube from "../../icons/youtube";
import Telegram from "../../icons/telegramCommunity";

const FOOTER_SOCIAL = [
  {
    name: "Telegram",
    icon: Telegram,
    url: "https://t.me/geopeoplegroup",
  },
  {
    name: "Facebook",
    icon: Facebook,
    url: "https://www.facebook.com/geo.pay.u/",
  },
  {
    name: "Instagram",
    icon: Instagram,
    url: "https://www.instagram.com/_geopay_/",
  },
  {
    name: "Twitter",
    icon: Twitter,
    url: "https://twitter.com/geo_pay",
  },
  {
    name: "YouTube",
    icon: YouTube,
    url: "https://www.youtube.com/channel/UCxDeUTbxF6eo5YXnXi-MSKg",
  },
];

const FOOTER_DOCS_TERMS = [
  {
    name: "footer.documents.item1",
    url: "https://processing.geo-pay.net/terms-of-use/en",
  },
  {
    name: "footer.documents.item2",
    url: "https://processing.geo-pay.net/privacy-policy/en",
  },
];

const FOOTER_DOCS_TERMS_UA = [
  {
    name: "footer.documents.item1",
    url: "https://processing.geo-pay.net/terms-of-use",
  },
  {
    name: "footer.documents.item2_ua",
    url: "https://processing.geo-pay.net/privacypolicy",
  },
];

const FOOTER_DOCS = [
  {
    name: "footer.documents.item3",
    url: "https://geo-pay.net/legal/cookie-policy",
  },
  {
    name: "footer.documents.item4",
    url: "https://geo-pay.net/legal/user-license-agreement",
  },
  {
    name: "footer.documents.item5",
    url: "https://geo-pay.net/legal/api-license-agreement",
  },
  {
    name: "footer.documents.item6",
    url: "https://geo-pay.net/legal/aml-gidelines",
  },
  {
    name: "footer.documents.item7",
    url: "https://geo-pay.net/legal/transparency-policy",
  },
];

export { FOOTER_SOCIAL, FOOTER_DOCS, FOOTER_DOCS_TERMS, FOOTER_DOCS_TERMS_UA };
