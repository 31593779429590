import React, { useEffect, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { NavLink as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

// locale
import { useTranslation } from "react-i18next";

// components
import ArrowDown from "../../../icons/arrowDown";

// styles
import "./index.scss";

//data
import { MENU_LINKS } from "./data";

const HeaderNav = () => {
  // translation hook
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  let [isOpen, setIsOpen] = useState(false);
  let [isHasActiveLink, setIsHasActiveLink] = useState(false);

  useEffect(() => {
    let status = !!MENU_LINKS.find((link) => link === pathname);
    setIsHasActiveLink(status);
  }, [pathname]);

  const onToggleMenuHandler = (flag: boolean) => {
    setIsOpen(flag);
  };

  const onCloseMenuHandler = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <ul className="header-nav">
      <li>
        <ClickAwayListener onClickAway={onCloseMenuHandler}>
          <div className="header-nav__menu">
            <button
              className={
                "header-nav__item" + (isHasActiveLink ? " active" : "")
              }
              onClick={() => onToggleMenuHandler(!isOpen)}
            >
              {t("header.nav.services.title")}
              <ArrowDown />
            </button>
            <div
              className={
                "app__menu header-nav__menu-list" +
                (isOpen ? " app__menu--active" : "")
              }
            >
              <ul>
                <li>
                  <RouterLink
                    to={"/lp/fiat-gateway"}
                    onClick={onCloseMenuHandler}
                  >
                    {t("header.nav.services.subnav.item1")}
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    to={"/lp/arbitration-tools"}
                    onClick={onCloseMenuHandler}
                  >
                    {t("header.nav.services.subnav.item2")}
                  </RouterLink>
                </li>
                {/* <li>
                  <RouterLink to={"/lp/wallet"} onClick={onCloseMenuHandler}>
                    {t("header.nav.services.subnav.item3")}
                  </RouterLink>
                </li> */}
                <li>
                  <RouterLink to={"/lp/p2p-trade"} onClick={onCloseMenuHandler}>
                    {t("header.nav.services.subnav.item4")}
                  </RouterLink>
                </li>
                <li>
                  <a href="/p2p/offers">
                    {t("header.nav.services.subnav.item5")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </ClickAwayListener>
      </li>
      {/* <li>
        <RouterLink to={"/lp/uahg-token"} className="header-nav__item">
          {t("header.nav.token.title")}
        </RouterLink>
      </li> */}
      <li>
        <RouterLink to={"/lp/partners"} className="header-nav__item">
          {t("header.nav.partners.title")}
        </RouterLink>
      </li>
    </ul>
  );
};

export default HeaderNav;
