import Locale from "../types/models/locale";

export const DEFAULT_LOCALE = "en";

export const languagesList: Locale[] = [
  {
    id: "uk",
    short: "UA",
    label: "Українська",
  },
  {
    id: "en",
    short: "EN",
    label: "English",
  },
  {
    id: "ru",
    short: "RU",
    label: "Русский",
  },
];
