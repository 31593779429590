const telegramCommunity = (props: any) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_9981_220162)">
      <path
        d="M24 48C37.258 48 48 37.258 48 24C48 10.742 37.258 0 24 0C10.742 0 0 10.742 0 24C0 37.258 10.742 48 24 48ZM10.982 23.48L34.122 14.558C35.196 14.17 36.134 14.82 35.786 16.444L35.788 16.442L31.848 35.004C31.556 36.32 30.774 36.64 29.68 36.02L23.68 31.598L20.786 34.386C20.466 34.706 20.196 34.976 19.576 34.976L20.002 28.87L31.122 18.824C31.606 18.398 31.014 18.158 30.376 18.582L16.634 27.234L10.71 25.386C9.424 24.978 9.396 24.1 10.982 23.48V23.48Z"
        fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_9981_220162">
        <rect width="48" height="48" fill="currentColor"/>
      </clipPath>
    </defs>
  </svg>
);

export default telegramCommunity;


