// core
import React from 'react';
import {useWindowSize} from 'react-use';

// components
import Header from '../../atoms/Header';
import HeaderMobile from '../../atoms/HeaderMobile';
import Footer from '../../atoms/Footer';

const StaticLayout = ({children}: any) => {
  const {width} = useWindowSize();
  return (
    <div className='app-main'>
      {width <= 1024 && <div className='app-main__bottom-fixed'><HeaderMobile/></div>}
      {width > 1024 && <div className='app-main__top'><Header/></div>}
      <div className='app-main__content'>{children}</div>
      <div className='app-main__bottom'>
        <Footer/>
      </div>
    </div>
  );
};

export default StaticLayout;
