const youTube = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.5577 4.94068L19.5827 5.10402C19.3411 4.24652 18.6886 3.58485 17.8594 3.34402L17.8419 3.33985C16.2827 2.91652 10.0086 2.91652 10.0086 2.91652C10.0086 2.91652 3.75025 2.90818 2.17525 3.33985C1.33025 3.58485 0.676912 4.24652 0.439412 5.08652L0.435245 5.10402C-0.147255 8.14651 -0.151422 11.8023 0.461078 15.0598L0.435245 14.8948C0.676912 15.7523 1.32941 16.414 2.15858 16.6548L2.17608 16.659C3.73358 17.0832 10.0094 17.0832 10.0094 17.0832C10.0094 17.0832 16.2669 17.0832 17.8427 16.659C18.6886 16.414 19.3419 15.7523 19.5794 14.9123L19.5836 14.8948C19.8486 13.4798 20.0002 11.8515 20.0002 10.1882C20.0002 10.1273 20.0002 10.0657 19.9994 10.004C20.0002 9.94735 20.0002 9.87985 20.0002 9.81235C20.0002 8.14818 19.8486 6.51985 19.5577 4.94068ZM8.00691 13.0423V6.96485L13.2286 10.0082L8.00691 13.0423Z"
      fill="currentColor"/>
  </svg>
);

export default youTube;


