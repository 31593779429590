// core
import React, {useEffect, useState} from 'react';

// components
import Sun from "../../icons/sun";
import Moon from "../../icons/moon";

// styles
import './index.scss'

const ThemeToggler = () => {

  let [isDarkTheme, setIsDarkTheme] = useState(false)

  useEffect(() => {
    if (localStorage.theme === 'dark' || (window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark');
      setIsDarkTheme(true);
    } else {
      document.documentElement.classList.remove('dark');
      setIsDarkTheme(false);
    }
  }, [])

  const onclickHandler = () => {
    document.documentElement.classList.add('app-theme--anim');
    setTimeout(()=>{
      document.documentElement.classList.remove('app-theme--anim');
    },200);

    if (isDarkTheme) {
      document.documentElement.classList.remove('dark');
      localStorage.theme = 'light';
      setIsDarkTheme(false)
    } else {
      document.documentElement.classList.add('dark');
      localStorage.theme = 'dark';
      setIsDarkTheme(true)
    }
  }
  return (
    <div className="theme-toggler">
      <button className='app__btn--link theme-toggler__btn' onClick={onclickHandler}>
        {isDarkTheme ? <Sun/> : <Moon/>}
      </button>
    </div>
  );
};

export default ThemeToggler;
