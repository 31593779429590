// core
import { useTranslation } from "react-i18next";

// components
import Logo from "../../icons/logo";

// styles
import "./index.scss";

// data
import {
  FOOTER_SOCIAL,
  FOOTER_DOCS,
  FOOTER_DOCS_TERMS,
  FOOTER_DOCS_TERMS_UA,
} from "./data";
import useGeolocation from "../../../hooks/useGeolocation";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const { data: geolocation } = useGeolocation();

  const openFreshChat = () => {
    //@ts-ignore
    window.fcWidget.open();
  };

  const currentLanguage = i18n.language === 'uk' ? 'ua' : i18n.language;

  return (
    <footer className="footer">
      <div className="app__wrapper">
        <div className="footer__grid">
          <div className="footer__left">
            <div className="footer__logo">
              <a href="/lp" title="GEO Pay">
                <Logo />
              </a>
            </div>
          </div>
          <div className="footer__right">
            <div className="footer__right-item">
              <div className="footer__title">{t("footer.social.title")}</div>
              <ul>
                {FOOTER_SOCIAL.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.url}
                      title={item.name}
                      className="app__link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {<item.icon />}
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer__right-item">
              <div className="footer__title">{t("footer.geoPay.title")}</div>
              <ul>
                {/* <li>
                  <RouterLink to={'/lp/vacancies'} title={t('footer.geoPay.item1')}
                              className='app__link'>
                    {t('footer.geoPay.item1')}
                  </RouterLink>
                </li> */}
                <li>
                  <a
                    href="https://blog.geo-pay.net/"
                    title={t("footer.geoPay.item2")}
                    target="_blank"
                    className="app__link"
                    rel="noreferrer"
                  >
                    {t("footer.geoPay.item2")}
                  </a>
                </li>
                <li>
                  <a
                    href={`https://blog.geo-pay.net/${currentLanguage}/academy`}
                    title={t("footer.geoPay.item3")}
                    className="app__link"
                  >
                    {t("footer.geoPay.item3")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__right-item">
              <div className="footer__title">{t("footer.support.title")}</div>
              <ul>
                <li>
                  <a
                    href="https://t.me/geopaysupport_bot "
                    title={t("footer.support.item1")}
                    className="app__link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("footer.support.item1")}
                  </a>
                </li>
                <li>
                  <span
                    onClick={openFreshChat}
                    title={t("footer.support.item2")}
                    className="app__link"
                  >
                    {t("footer.support.item2")}
                  </span>
                </li>
              </ul>
            </div>
            <div className="footer__right-item">
              <div className="footer__title">{t("footer.documents.title")}</div>
              <ul>
                {geolocation?.countryCode === "UA"
                  ? FOOTER_DOCS_TERMS_UA.map((item) => (
                      <li key={`footer-item-${item.name}`}>
                        <a
                          href={item.url}
                          title={item.name}
                          className="app__link"
                        >
                          {t(item.name)}
                        </a>
                      </li>
                    ))
                  : FOOTER_DOCS_TERMS.map((item) => (
                      <li key={`footer-item-${item.name}`}>
                        <a
                          href={item.url}
                          title={item.name}
                          className="app__link"
                        >
                          {t(item.name)}
                        </a>
                      </li>
                    ))}
                {geolocation?.countryCode !== "UA" &&
                  FOOTER_DOCS.map((item, index) => (
                    <li key={`footer-item-${item.name}`}>
                      <a
                        href={item.url}
                        title={item.name}
                        className="app__link"
                      >
                        {t(item.name)}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
