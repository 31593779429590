import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { NavLink as RouterLink } from "react-router-dom";

// locale
import { useTranslation } from "react-i18next";

// components
import ArrowDown from "../../../icons/arrowDown";

// styles
import "./index.scss";

//data

const HeaderNavMobile = () => {
  // translation hook
  const { t, i18n } = useTranslation();

  let [isOpen, setIsOpen] = useState(false);

  const onToggleMenuHandler = (flag: boolean) => {
    setIsOpen(flag);
  };

  const onCloseMenuHandler = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <ul className="header-nav-mobile">
      <li>
        <ClickAwayListener onClickAway={onCloseMenuHandler}>
          <div className="header-nav-mobile__menu">
            <button
              className="header-nav-mobile__item"
              onClick={() => onToggleMenuHandler(!isOpen)}
            >
              {t("header.nav.services.title")} <ArrowDown />
            </button>
            <div
              className={
                "app__menu header-nav-mobile__menu-list" +
                (isOpen ? " app__menu--active" : "")
              }
            >
              <ul>
                <li>
                  <RouterLink
                    to={"/lp/fiat-gateway"}
                    onClick={onCloseMenuHandler}
                  >
                    {t("header.nav.services.subnav.item1")}
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    to={"/lp/arbitration-tools"}
                    onClick={onCloseMenuHandler}
                  >
                    {t("header.nav.services.subnav.item2")}
                  </RouterLink>
                </li>
                {/* <li>
                  <RouterLink to={"/lp/wallet"} onClick={onCloseMenuHandler}>
                    {t("header.nav.services.subnav.item3")}
                  </RouterLink>
                </li> */}
                <li>
                  <RouterLink to={"/lp/p2p-trade"} onClick={onCloseMenuHandler}>
                    {t("header.nav.services.subnav.item4")}
                  </RouterLink>
                </li>

                {/* <li className="header-nav-mobile__menu-item--border">
                  <RouterLink
                    to={"/lp/uahg-token"}
                    onClick={onCloseMenuHandler}
                  >
                    {t("header.nav.token.title")}
                  </RouterLink>
                </li> */}
                <li>
                  <RouterLink to={"/lp/partners"} onClick={onCloseMenuHandler}>
                    {t("header.nav.partners.title")}
                  </RouterLink>
                </li>
              </ul>
            </div>
          </div>
        </ClickAwayListener>
      </li>
    </ul>
  );
};

export default HeaderNavMobile;
