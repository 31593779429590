import React, {useState} from 'react';
import ClickAwayListener from 'react-click-away-listener';

// locale
import {useTranslation} from 'react-i18next';

// components
import ArrowDown from '../../icons/arrowDown';

// styles
import './index.scss';

//data
import {languagesList} from '../../../locales/data';

const LangToggler = () => {
  // translation hook
  const {t, i18n} = useTranslation();

  let [isOpen, setIsOpen] = useState(false)

  const onChangeLangHandler = (language: any) => {
    onCloseLangListHandler();
    i18n.changeLanguage(language);
    // window.location.reload();
  };

  const localization =
    i18n.language && i18n.language.length > 2
      ? i18n.language.slice(0, 2)
      : i18n.language;


  // current locale language
  const currentLanguage = languagesList.find(
    lang => lang.id === localization,
  );

  const onToggleLangListHandler = (flag:boolean) => {
    setIsOpen(flag);
  }

  const onCloseLangListHandler = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  }

  return (
    <ClickAwayListener onClickAway={onCloseLangListHandler}>
      <div className='lang-toggler'>
        <button className='app__btn--link lang-toggler__current' onClick={()=>onToggleLangListHandler(!isOpen)}>
          <span>{currentLanguage?.short}</span>
          <ArrowDown/>
        </button>
        <div className={'app__menu lang-toggler__menu-list' + (isOpen ? ' app__menu--active' : '')}>
          <ul>
            {languagesList.map(lang => (
              <li key={lang.id}>
                <button onClick={() => onChangeLangHandler(lang.id)} className={(lang.id === currentLanguage?.id ? ' lang-toggler__menu-list--current' : '')}>
                  {lang.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default LangToggler;
